import { alias, Executable } from "../../../services/executable-models";
import { AppThunk } from "../../store";
import { GameEdition, SettingType } from "../config-editor/config-editor-slice";
import { receiveTemplates, ActionCategory, ActionTemplate, PurchaseCategory, PurchaseTemplate, SettingsCategory, DropdownSettingTemplate, TextboxSettingTemplate, SliderSettingTemplate } from "./templates-slice";

export const templatesActions = {
    loadTemplates: (): AppThunk => async (dispatch, getState) => {
        const actions = getDefaultActionTemplates();
        const purchases = getPurchaseTemplates();
        const settings = getDefaultSettingTemplates();
        dispatch(receiveTemplates({actions, purchases, settings}));
    },
}

const getDefaultActionTemplates = (): ActionCategory[] => {

    type ActionOptions = {
        dependencies?: Executable[];
        game?: GameEdition;
    }

    function action(cmd: string, isMeta: boolean, options?: ActionOptions): ActionTemplate {
        return {
            cmd,
            isMeta,
            dependencies: options?.dependencies,
            game: options?.game,
        }
    }
    return [
        {
            name: 'Equipment',
            items: [
                action("slot1", false),
                action("slot2", false),
                action("slot3", false),
                action("slot4", false),
                action("slot5", false),
                action("slot6", false),
                action("slot7", false),
                action("slot8", false),
                action("slot9", false),
                action("slot10", false),
                action("slot11", false),
                action("slot12", false),
                action("invprev", false),
                action("invnext", false),
                action("lastinv", false),
                action("cl_show_team_equipment", true),
                action("quickinv", true),
            ]
        },
        {
            name: 'Buy',
            items: [
                action("buymenu", false),
                action("autobuy", false),
                action("rebuy", false),
            ]
        },
        {
            name: 'Common',
            items: [
                action("attack", true),
                action("attack2", true),
                action("reload", true),
                action("drop", false),
                action("use", true),
                action("showscores", true),
            ]
        },
        {
            name: 'Movement',
            items: [
                action("forward", true),
                action("back", true),
                action("moveleft", true, { game: GameEdition.CSGO }),
                action("moveright", true, { game: GameEdition.CSGO }),
                action("left", true, { game: GameEdition.CS2 }),
                action("right", true, { game: GameEdition.CS2 }),
                action("jump", true),
                action("duck", true),
                action("speed", true, { game: GameEdition.CSGO }),
                action("sprint", true, { game: GameEdition.CS2 }),
            ]
        },
        {
            name: 'Communication',
            items: [
                action("voicerecord", true),
                action("radio1", false),
                action("radio2", false),
                action("radio3", false),
                action("radialradio", true),
                action("radialradio2", true),
                action("radialradio3", true),
                action("messagemode2", false),
                action("messagemode", false),
                action("player_ping", false),
            ]
        },
        {
            name: 'Warmup',
            items: [
                action("god", false),
                action("noclip", false),
                action("impulse 101", false),
                action("mp_warmup_start", false),
                action("mp_warmup_end", false),
                action("mp_swapteams", false),
                action("endround", false),
                action("bot_add_t", false),
                action("bot_add_ct", false),
                action("bot_place", false),
                action("bot_kick", false),
                action("sv_rethrow_last_grenade", false),
            ]
        },
        {
            name: 'Other',
            items: [
                action("lookatweapon", true),
                action("spray_menu", true),
                action("unbindall", false),
                action("r_cleardecals", false, { game: GameEdition.CSGO }),
                action("callvote", false),
                action("teammenu", false),
                action("toggleconsole", false),
                action("clear", false),
                action("cl_clearhinthistory", false),
                action("screenshot", false),
                action("pause", false),
                action("disconnect", false),
                action("quit", false),
                action("switchhandsleft", false, { game: GameEdition.CS2 }),
                action("switchhandsright", false, { game: GameEdition.CS2 }),
                action("switchhands", false, { game: GameEdition.CS2 }),
            ]
        },
        {
            name: 'Demo',
            items: [
                action("demo_resume", false),
                action("demo_togglepause", false),
            ]
        },
        {
            name: 'Scripts',
            items: [
                action('jump_throw', true, {
                    dependencies: [
                        alias('+jump_throw', ['+jump', '-attack', '-attack2']),
                        alias('-jump_throw', ['-jump']),
                    ]
                }),
                action('fast_bomb_drop', true, {
                    dependencies: [
                        alias('+fast_bomb_drop', ['slot5']),
                        alias('-fast_bomb_drop', ['drop']),
                    ]
                }),
                action('display_damage_on', false, {
                    dependencies: [
                        alias('display_damage_on', [
                            'developer 1',
                            'con_filter_text "Damage Given"',
                            'con_filter_text_out "Player:"',
                            'con_filter_enable 2'
                        ]),
                    ]
                }),
                action('display_damage_off', false, {
                    dependencies: [
                        alias('display_damage_off', [
                            'con_filter_enable 0',
                            'developer 0',
                        ]),
                    ]
                }),
            ]
        },
    ];
}

function getPurchaseTemplates(): PurchaseCategory[] {
    function weapon(name: string, price: number | { t?: number, ct?: number }): PurchaseTemplate {
        return {
            name,
            priceT: typeof price === 'number' ? price : price.t ?? 0,
            priceCt: typeof price === 'number' ? price : price.ct ?? 0,
        }
    }

    return [
        {
            name: 'Pistols',
            items: [
                weapon('glock', { t: 200 }),
                weapon('hkp2000', { ct: 200 }),
                weapon('usp_silencer', { ct: 200 }),
                weapon('elite', 300),
                weapon('p250', 300),
                weapon('tec9', { t: 500 }),
                weapon('deagle', 700),
                weapon('fiveseven', { ct: 500 }),
                weapon('cz75a', 500),
                weapon('revolver', 600),
            ]
        },
        {
            name: 'Mid-Tier',
            items: [
                weapon('mac10', { t: 1050 }),
                weapon('mp7', 1500),
                weapon('ump45', 1200),
                weapon('p90', 2350),
                weapon('bizon', 1400),
                weapon('mp9', { ct: 1250 }),
                weapon('nova', 1050),
                weapon('xm1014', 2000),
                weapon('mag7', { ct: 1300 }),
                weapon('m249', 5200),
                weapon('negev', 1700),
                weapon('sawedoff', { t: 1100 }),
                weapon('mp5sd', 1500),
            ]
        },
        {
            name: 'Rifles',
            items: [
                weapon('galilar', { t: 1800 }),
                weapon('famas', { ct: 2050 }),
                weapon('ak47', { t: 2700 }),
                weapon('ssg08', 1700),
                weapon('sg556', { t: 3000 }),
                weapon('awp', 4750),
                weapon('g3sg1', { t: 5000 }),
                weapon('scar20', { ct: 5000 }),
                weapon('m4a1', { ct: 3000 }),
                weapon('m4a1_silencer', { ct: 2900 }),
                weapon('aug', { ct: 3300 }),
            ]
        },
        {
            name: 'Equipment',
            items: [
                weapon('vest', 650),
                weapon('vesthelm', 1000),
                weapon('taser', 200),
                weapon('defuser', { ct: 400 }),
            ]
        },
        {
            name: 'Grenade',
            items: [
                weapon('molotov', { t: 400, ct: 500 }),
                weapon('decoy', 50),
                weapon('flashbang', 200),
                weapon('flashbang2', 200),
                weapon('hegrenade', 300),
                weapon('smokegrenade', 300),
            ]
        },
    ]
}

function getDefaultSettingTemplates(): SettingsCategory[] {

    function range(min: number, max: number): number[] {
        const arr: number[] = [];
        for (let i = min; i <= max; i++)
            arr.push(i);
        return arr;
    }
    function combobox(cmd: string, options: number[] | string[], defaultIndex: number, displayNames?: Record<number | string, string>): DropdownSettingTemplate {
        return {
            type: SettingType.Dropdown,
            cmd,
            options,
            defaultIndex,
            displayNames
        }
    }

    function textbox(cmd: string, defaultValue: string): TextboxSettingTemplate {
        return {
            type: SettingType.Textbox,
            cmd,
            defaultValue
        }
    }

    function number(cmd: string, defaultValue: number, isInteger: boolean = true): TextboxSettingTemplate {
        return {
            type: SettingType.Textbox,
            cmd,
            defaultValue,
            isInteger,
        }
    }

    function slider(cmd: string, defaultValue: number, min: number, max: number, step: number): SliderSettingTemplate {
        return {
            type: SettingType.Slider,
            cmd,
            default: defaultValue,
            min,
            max,
            step,
        }
    }

    const toggle = [0, 1];
    const primaryWeapons: string[] = ["weapon_m4a4", "weapon_ak47", "weapon_awp"];
    const secondaryWeapons: string[] = ["weapon_hkp2000", "weapon_glock", "weapon_deagle"];

    return [
        {
            name: 'Mouse settings',
            items: [
                number('sensitivity', 2.5, false),
                number('zoom_sensitivity_ratio_mouse', 1, true),
                combobox('m_rawinput', toggle, 1),
                combobox('m_customaccel', toggle, 0),
                slider('m_customaccel_exponent', 1.05, 0.05, 10, 0.05),
            ]
        },
        {
            name: 'Client commands',
            items: [
                combobox('cl_autowepswitch', toggle, 1),
                combobox('cl_showloadout', toggle, 1),
                slider('cl_bob_lower_amt', 21, 5, 30, 1),
                slider('cl_bobamt_lat', 0.4, 0.1, 2, 0.1),
                slider('cl_bobamt_vert', 0.25, 0.1, 2, 0.1),
                slider('cl_bobcycle', 0.98, 0.1, 2, 0.01),
                number('cl_clanid', 0),
                combobox('cl_color', range(0, 4), 0, { 0: 'Yellow', 1: 'Purple', 2: 'Green', 3: 'Blue', 4: 'Orange' }),
                combobox('cl_dm_buyrandomweapons', toggle, 1),
                combobox('cl_draw_only_deathnotices', toggle, 1),
                combobox('cl_hud_color', range(0, 11), 0, {
                    0: 'Gray', 1: 'White', 2: 'Light Blue', 3: 'Blue',
                    4: 'Purple', 5: 'Red', 6: 'Orange', 7: 'Yellow',
                    8: 'Lime', 9: 'Aquamarine', 10: 'Pink', 11: 'Normal'
                }),
                combobox('cl_hud_healthammo_style', range(0, 1), 0),
                slider('cl_hud_radar_scale', 1, 0.8, 1.3, 0.1),
                slider('cl_hud_background_alpha', 1, 0, 1, 0.1),
                combobox('cl_hud_playercount_pos', range(0, 1), 0),
                combobox('cl_hud_playercount_showcount', range(0, 1), 0),
                slider('hud_scaling', 0.95, 0.5, 0.95, 0.05),
                combobox('cl_mute_enemy_team', toggle, 0),
                number('cl_pdump', -1),
                combobox('cl_radar_always_centered', toggle, 0),
                slider('cl_radar_icon_scale_min', 0.7, 0.4, 1, 0.01),
                slider('cl_radar_scale', 0.7, 0.25, 1, 0.01),
                combobox('cl_righthand', toggle, 0),
                combobox('cl_showfps', toggle, 0),
                combobox('cl_show_clan_in_death_notice', toggle, 0),
                combobox('cl_showpos', toggle, 0),
                combobox('cl_teammate_colors_show', toggle, 0),
                combobox('cl_teamid_overhead_always', toggle, 0),
                slider('cl_timeout', 30, 4, 30, 1),
                combobox('cl_use_opens_buy_menu', toggle, 1),
                combobox('cl_buywheel_donate_key', range(0, 2), 0, { 0: 'Left Ctrl', 1: 'Left Alt', 2: 'Left Shift' }),
                slider('cl_viewmodel_shift_left_amt', 1.5, 0.5, 2, 0.05),
                slider('cl_viewmodel_shift_right_amt', 0.75, 0.25, 2, 0.05),
                combobox('cl_cmdrate', [64, 128], 1),
                combobox('cl_updaterate', [64, 128], 1),
                number('fps_max', 300, true),
                number('fps_max_menu', 120, true),
                textbox('play', 'Check out arguments: http://paste.dy.fi/isy/plain'),
                number('snd_mapobjective_volume', 1, false),
                combobox('cl_radar_scale_dynamic', toggle, 0),
            ]
        },
        {
            name: 'Crosshair',
            items: [
                combobox('cl_crosshair_drawoutline', toggle, 0),
                slider('cl_crosshaircolor', 1, 0, 5, 1),
                slider('cl_crosshaircolor_r', 50, 0, 255, 1),
                slider('cl_crosshaircolor_g', 50, 0, 255, 1),
                slider('cl_crosshaircolor_b', 50, 0, 255, 1),
                slider('cl_crosshair_dynamic_maxdist_splitratio', 0.35, 0, 1, 0.1),
                slider('cl_crosshair_dynamic_splitalpha_innermod', 1, 0, 1, 0.01),
                slider('cl_crosshair_dynamic_splitalpha_outermod', 0.5, 0.3, 1, 0.01),
                number('cl_crosshair_dynamic_splitdist', 7),
                slider('cl_crosshair_outlinethickness', 1, 0.1, 3, 0.1),
                combobox('cl_crosshair_sniper_show_normal_inaccuracy', toggle, 0),
                number('cl_crosshair_sniper_width', 1, false),
                slider('cl_crosshairalpha', 200, 0, 255, 1),
                combobox('cl_crosshairdot', toggle, 1),
                number('cl_crosshairgap', 1),
                combobox('cl_crosshair_t', toggle, 1),
                combobox('cl_crosshairgap_useweaponvalue', toggle, 1),
                number('cl_crosshairsize', 5, false),
                slider('cl_crosshairstyle', 2, 0, 5, 1),
                number('cl_crosshairthickness', 0.5, false),
                combobox('cl_crosshairusealpha', toggle, 1),
                number('cl_fixedcrosshairgap', 3, false),
            ]
        },
        {
            name: 'Other',
            items: [
                textbox('say', 'configmeta.exideprod.com'),
                textbox('say_team', 'configmeta.exideprod.com'),
                textbox('exec', 'config'),
                number('rate', 786432),
                textbox('connect', '12.34.56.78:27015'),
                textbox('map', 'de_mirage'),
                textbox('echo', 'configmeta.exideprod.com'),
                textbox('record', 'demo_name'),
                combobox('developer', toggle, 0),
                combobox('gameinstructor_enable', toggle, 0),
                combobox('r_drawothermodels', range(0, 3), 1),
                textbox('host_writeconfig', 'config_name'),
                slider('mat_monitorgamma', 2.2, 1.6, 2.6, 0.1),
                textbox('mat_setvideomode', '1920 1080 0'),
                number('mm_dedicated_search_maxping', 150),
                number('host_timescale', 1, false),
                number('demo_timescale', 1, false),
                textbox('give', ''),
            ]
        },
        {
            name: 'Server',
            items: [
                number('sv_airaccelerate', 12),
                number('sv_accelerate', 5.5, false),
                combobox('sv_showimpacts', range(0, 3), 0),
                number('mp_restartgame', 1),
                combobox('mp_solid_teammates', toggle, 0),
                combobox('mp_ct_default_primary', primaryWeapons, 0),
                combobox('mp_t_default_primary', primaryWeapons, 1),
                combobox('mp_ct_default_secondary', secondaryWeapons, 0),
                combobox('mp_t_default_secondary', secondaryWeapons, 1),
                combobox('mp_ignore_round_win_conditions', toggle, 0),
                combobox('sv_cheats', toggle, 0),
                combobox('sv_talk_enemy_dead', toggle, 0),
                combobox('sv_talk_enemy_living', toggle, 0),
                slider('sv_infinite_ammo', 0, 0, 2, 1),
                slider('mp_roundtime', 5, 1, 60, 1),
                slider('mp_freezetime', 6, 0, 60, 1),
                combobox('mp_buy_anywhere', toggle, 0),
                slider('mp_maxmoney', 16000, 0, 60000, 1000),
                number('mp_startmoney', 800),
                combobox('mp_autoteambalance',  toggle, 1),
                slider('mp_limitteams', 2, 0, 30, 1),
                combobox('sv_grenade_trajectory', toggle, 0),
                combobox('sv_grenade_trajectory_prac_pipreview ', toggle, 0),
                number('ammo_grenade_limit_default', 1),
                number('ammo_grenade_limit_flashbang', 2),
                number('ammo_grenade_limit_total', 3),
                slider('ff_damage_reduction_grenade', 0.25, 0, 1, 0.01),
                slider('ff_damage_reduction_bullets', 0.1, 0, 1, 0.01),
                slider('ff_damage_reduction_other', 0.25, 0, 1, 0.01),
                slider('ff_damage_reduction_grenade_self', 1, 0, 1, 0.01),
                number('mp_afterroundmoney', 0),
                combobox('mp_autokick', toggle, 1),
                combobox('mp_autoteambalance', toggle, 1),
                number('mp_buytime', 90),
                number('mp_c4timer', 40),
                combobox('mp_death_drop_defuser', toggle, 1),
                slider('mp_death_drop_grenade', 2, 0, 3, 1),
                slider('mp_death_drop_gun', 1, 0, 2, 1),
                slider('mp_defuser_allocation', 0, 0, 2, 1),
                combobox('mp_do_warmup_period', toggle, 1),
                slider('mp_forcecamera', 1, 0, 2, 1),
                number('mp_force_pick_time', 15),
                slider('mp_free_armor', 0, 0, 2, 1),
                slider('mp_freezetime', 6, 0, 60, 1),
                number('mp_friendlyfire', 0),
                number('mp_halftime', 0),
                slider('mp_halftime_duration', 15, 0, 300, 1),
                slider('mp_join_grace_time', 0, 0, 30, 1),
                slider('mp_logdetail', 0, 0, 3, 1),
                combobox('mp_match_can_clinch', toggle, 1),
                combobox('mp_match_end_restart', toggle, 0),
                slider('mp_match_restart_delay', 25, 1, 9999, 1),
                number('mp_maxrounds', 30),
                slider('mp_molotovusedelay', 15, 0, 30, 1),
                combobox('mp_overtime_enable', toggle, 0),
                number('mp_overtime_maxrounds', 6),
                number('mp_overtime_startmoney', 10000),
                combobox('mp_playercashawards', toggle, 1),
                slider('mp_playerid', 0, 0, 2, 1),
                slider('mp_playerid_delay', 0.4, 0, 1, 0.1),
                slider('mp_playerid_hold', 0.1, 0, 1, 0.05),
                number('mp_respawn_immunitytime', 4),
                number('mp_round_restart_delay', 7),
                number('mp_roundtime_defuse', 1.92, false),
                combobox('mp_teamcashawards', toggle, 1),
                number('mp_timelimit', 5),
                combobox('mp_tkpunish', toggle, 0),
                number('mp_warmuptime', 30),
                combobox('mp_weapons_allow_map_placed', toggle, 0),
                combobox('mp_weapons_allow_zeus', toggle, 1),
                number('mp_win_panel_display_time', 3),
                number('spec_freeze_time', 3, false),
                number('spec_freeze_panel_extended_time', 0, false),
                number('sv_stopspeed', 80),
                combobox('sv_allow_votes', toggle, 1),
                combobox('sv_allow_wait_command', toggle, 1),
                combobox('sv_alltalk', toggle, 0),
                combobox('sv_damage_print_enable', toggle, 1),
                combobox('sv_dc_friends_reqd', toggle, 0),
                combobox('sv_deadtalk', toggle, 0),
                combobox('sv_full_alltalk', toggle, 0),
                combobox('sv_gameinstructor_disable', toggle, 0),
                combobox('sv_ignoregrenaderadio', toggle, 0),
                combobox('sv_competitive_official_5v5', toggle, 0),
                combobox('sv_kick_players_with_cooldown', toggle, 0),
                combobox('sv_lan', toggle, 0),
                number('sv_maxrate', 0),
                combobox('sv_competitive_minspec', toggle, 1),
                combobox('sv_pausable', toggle, 0),
                combobox('sv_pure', toggle, 1),
                combobox('sv_pure_kick_clients', toggle, 1),
                number('sv_spawn_afk_bomb_drop_time', 15),
                combobox('sv_steamgroup_exclusive', toggle, 0),
                combobox('sv_voiceenable', toggle, 0),

            ]
        },
        {
            name: 'Voice and sound',
            items: [
                slider('volume', 1, 0, 1, 0.05),
                combobox('voice_enable', toggle, 1),
                combobox('voice_loopback', toggle, 1),
                slider('voice_scale', 1, 0, 1, 0.05),
                slider('snd_roundstart_volume', 1, 0, 1, 0.05),
                slider('snd_roundend_volume', 1, 0, 1, 0.05),
                slider('snd_tensecondwarning_volume', 1, 0, 1, 0.05),
                slider('snd_deathcamera_volume', 1, 0, 1, 0.05),
                slider('snd_mvp_volume', 1, 0, 1, 0.05),
            ]
        },
        {
            name: 'net_graph',
            items: [
                combobox('net_graph', toggle, 0),
                number('net_graphheight', 64),
                number('net_graphpos', 1),
                combobox('net_graphproportionalfont', toggle, 0),
            ]
        },
        {
            name: 'Bots',
            items: [
                combobox('bot_stop', toggle, 0),
                combobox('bot_mimic', toggle, 0),
                combobox('bot_crouch', toggle, 0),
                slider('bot_mimic_yaw_offset', 0, 0, 180, 5),
                number('bot_quota', 10),
            ]
        },
        {
            name: 'Cash',
            items: [
                number('cash_player_bomb_defused', 300),
                number('cash_player_bomb_planted', 300),
                number('cash_player_damage_hostage', -30),
                number('cash_player_interact_with_hostage', 150),
                number('cash_player_killed_enemy_default', 300),
                number('cash_player_killed_enemy_factor', 1),
                number('cash_player_killed_hostage', -1000),
                number('cash_player_killed_teammate', -300),
                number('cash_player_rescued_hostage', 1000),
                number('cash_team_elimination_bomb_map', 3250),
                number('cash_team_hostage_alive', 150),
                number('cash_team_hostage_interaction', 150),
                number('cash_team_loser_bonus', 1400),
                number('cash_team_loser_bonus_consecutive_rounds', 500),
                number('cash_team_planted_bomb_but_defused', 800),
                number('cash_team_rescued_hostage', 750),
                number('cash_team_terrorist_win_bomb', 3500),
                number('cash_team_win_by_defusing_bomb', 3500),
                number('cash_team_win_by_hostage_rescue', 3500),
                number('cash_player_get_killed', 0),
                number('cash_player_respawn_amount', 0),
                number('cash_team_elimination_hostage_map_ct', 2000),
                number('cash_team_elimination_hostage_map_t', 1000),
                number('cash_team_win_by_time_running_out_bomb', 3250),
                number('cash_team_win_by_time_running_out_hostage', 3250),
            ]
        },
    ]
}